import React from 'react';
import '../css/parallax.css';

const ParallaxSection = () => {
	return (
		<div className="parallax-container">
		</div>
	);
};

export default ParallaxSection;
