import React from 'react'; 
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import ContactForm from "./ContactForm";
import ParallaxSection from './Parallax';

const Home = () => {
	
	return(
		<>
			<Helmet>
				<title>Mobile Dog Grooming Fareham | Inspired Canine Grooming</title>
				<meta 
					name="description" 
					content="Pamper your pooch with Inspired Canine Grooming's professional mobile dog grooming services in Fareham and across Hampshire. We come to you! ➡️ Book your appointment online today." 
				/>
			</Helmet>
			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				id="home" className="hero"
			>
				<h2>Mobile Dog Grooming</h2>
				<p>Professional grooming services with a gentle touch. Operating in Fareham and the surrounding area.</p>
				<br/>
				<h3>Book now! <a href="tel:07779121111">0777 912 1111</a></h3>
			</motion.section>

			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				id="about"
				className="about"
			>
				<div className="centralCont">
					<h2>Stress-Free Dog Grooming to Your Door</h2>
				</div>
				<div className="centralCont aboutWrap">
					<div className="info">
						<p>Say goodbye to the stress of transporting your dog to the groomer! Inspired Canine Grooming offers a convenient alternative with our mobile grooming service in and around Fareham, Hampshire.</p>
						<br/>
						<p>We come to you, providing a relaxed and personalized experience for your beloved companion.</p>
						<br/>
						<p>Our services include everything from breed-standard haircuts and de-matting to ear cleaning and nail care. With Inspired Canine Grooming, your dog will enjoy a spa day in the comfort of their own home, leaving them looking and feeling fantastic.</p>
						<br/>
						<p>Read more about our services <a href="/Services">here</a></p>
					</div>
					<img src="resources/van.png" className="imgCap" alt="Inspired Canine Grooming Van"/>
				</div>
			</motion.section>
			<ParallaxSection />
			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				id="about"
				className="about"
			>
				<div className="centralCont">
					<h2>Putting Your Dog's Comfort First</h2>
				</div>
				<div className="centralCont aboutWrap">
					<img src="resources/dog2.png" className="imgCap" alt="Inspired Canine Grooming Van"/>
					<div className="info">
						<p>Every dog is unique, and at Inspired Canine Grooming, we celebrate those differences. We believe in providing truly personalized care, taking the time to understand your dog's individual needs and preferences.</p>
						<br/>
						<p>Whether your dog is anxious, energetic, or simply enjoys being pampered, we tailor our approach to ensure a comfortable and enjoyable grooming experience.</p>
					</div>
				</div>
			</motion.section>
			
			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				id="bookNow"
				className="bookNow"
			>
				<h2>Book now! <a href="tel:07779121111">0777 912 1111</a></h2>
			</motion.section>

			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				className="testimonials"
			>
				<div className="centralCont">
					<h2>Testimonials</h2>
					<motion.div
						whileInView={{ y: 0, opacity: 1, scale: 1 }}
						initial={{ y: 50, opacity: 0, scale: 0.5 }}
						transition={{ duration: 0.4 }}
						className="testimonial"
					>
						<p>"Steve always does a fantastic job! Wouldn’t have anyone else groom my boy"</p>
						<p className="author">- Alison Babs (Facebook)</p>
					</motion.div>
					<motion.div
						whileInView={{ y: 0, opacity: 1, scale: 1 }}
						initial={{ y: 50, opacity: 0, scale: 0.5 }}
						transition={{ duration: 0.4 }}
						className="testimonial"
					>
						<p>"And another fantastic groom from Steve for my Cavilier Daisy. Steve is great with them and is so patient. In between cuts I try and trim her feet as she bites them but it usually ends up with me losing. Steve comes along and within the hour she's looking fab"</p>
						<p className="author">- Beccy Pagan (Facebook)</p>
					</motion.div>
					<motion.div
						whileInView={{ y: 0, opacity: 1, scale: 1 }}
						initial={{ y: 50, opacity: 0, scale: 0.5 }}
						transition={{ duration: 0.4 }}
						className="testimonial"
					>
						<p>"Steve is fantastic! Our Chihuahua Ruby can be quite nervous with men, but she absolutely loves Steve & loves having her bath & groom. She always comes home looking & smelling amazing. Can't thank you enough!!"</p>
						<p className="author">- Emily Price (Facebook)</p>
					</motion.div>
					<motion.div
						whileInView={{ y: 0, opacity: 1, scale: 1 }}
						initial={{ y: 50, opacity: 0, scale: 0.5 }}
						transition={{ duration: 0.4 }}
						className="testimonial"
					>
						<p>"Steve is a miracle worker! He grooms our 2 springer spaniels. One who is pretty headstrong & the other that's very timid. They always come back in looking amazing & happy. So pleased to have found someone we trust with our precious pups! Thanks Steve!"</p>
						<p className="author">- Lucy Holliday (Facebook)</p>
					</motion.div>
				</div>
			</motion.section>
			<motion.section
				whileInView={{ y: 0, opacity: 1, scale: 1 }}
				initial={{ y: 50, opacity: 0, scale: 0.5 }}
				transition={{ duration: 0.4 }}
				id="contact"
				className="contact contactHome"
			> 
				<motion.div
					whileInView={{ y: 0, opacity: 1, scale: 1 }}
					initial={{ y: 50, opacity: 0, scale: 0.5 }}
					transition={{ duration: 0.6 }}
					className="container contactHomeCont"
				>
					<ContactForm />
				</motion.div>
			</motion.section>
		</>
	)
};

export default Home;
